/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import LikeButton from "../../../components/likeButton";


const OtherModal = ({ photo, onClose }) => {
    return (
        <div className="modal-overlay" onClick={onClose}>
           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={photo.photoURL} className="modal-photo" /> 
            <p className="modal-description"> {photo.description} </p>
            <p className="modal-time">{new Date(photo.time).toLocaleDateString()} </p>
            <button className="modal-close" onClick={onClose}>X</button>
            <LikeButton className="home-like" photoId={photo.id} ownerId={photo.ownerId} />
           </div>
        </div>
    )
}

export default OtherModal;