/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from '../../../firebase/userContext';
import NavBar from '../../../components/navBar';
import settingIcon from '../../../assets/icons/settingIcon.png';
import messageIcon from '../../../assets/icons/messageIcon.png';
import shareIcon from '../../../assets/icons/shareIcon.png'
import OtherGallery from './otherGallery.js';
import { useNavigate } from 'react-router-dom';

const OtherProfile = () => {
    const { username } = useParams();
    const { fetchUserProfileByUsername, followUser, unfollowUser, isFollowing } = useCurrentUser();
    const [profileData, setProfileData] = useState(null);
    const [showEmail, setShowEmail] = useState(false);
    const [followingStatus, setFollowingStatus] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const loadProfileData = async () => {
            const data = await fetchUserProfileByUsername(username);
            if (data) {
                setProfileData(data);
                // Check if current user is following this user
                const following = await isFollowing(data);
                setFollowingStatus(following);
            }
        };
        loadProfileData();
    }, [username, fetchUserProfileByUsername, isFollowing]);

    const toggleEmail = () => {
        setShowEmail(prev => !prev);
    };

    const handleFollowToggle = async () => {
        if (followingStatus) {
            await unfollowUser(profileData);
            setFollowingStatus(false);
        } else {
            await followUser(profileData);
            setFollowingStatus(true);
        }
    };

    const toggleShare = () => {
        setShowShare((prev) => !prev);
        setCopySuccess(false);
    };

    const copyLink = () => {
        const profileUrl = `profileland.net/creator/${username}`;
        navigator.clipboard.writeText(profileUrl)
            .then(() => {
                setCopySuccess(true);
                setShowShare(false); // Close the modal
                setTimeout(() => setCopySuccess(false), 2000); // Hide success message after 2 seconds
            })
            .catch((err) => {
                console.error('Failed to copy link:', err);
            });
    };



    return (
        <div className='layout'>
            <img src={settingIcon} className='settingicon-profile' onClick={() => navigate('/setting')} />
            <img src={messageIcon} className='messageicon-profile' onClick={toggleEmail}/>
            <img src={shareIcon} className='share-icon-op' onClick={toggleShare}/>
            <NavBar />

            <h1 className='profile-name'>{profileData?.name}</h1>
            <div className='profilephoto'>
                {profileData?.profilePhotoUrl ? (
                    <img src={profileData.profilePhotoUrl} className="profilephoto-display" />
                ) : (
                    <p>Profile Photo</p>
                )}
            </div>
            <p className='profile-category'>{profileData?.category}</p>
            <div className='profile-bio'>
               <p> {profileData?.bio} </p>
            </div>
            <button className='editprofile-button' onClick={handleFollowToggle}>
                {followingStatus ? 'Following' : 'Follow'}
            </button>

            <OtherGallery username={username}/>

            {showEmail && (
                <div className="email-popup">
                    <p> {profileData?.workEmail ? profileData.workEmail : "no email"} </p>
                    <button className="email-button" onClick={toggleEmail}>X</button>
                </div>
            )}

            {showShare && (
                <div className="share-popup">
                    <p className="sharelink">{`profileland.net/creator/${username}`}</p>
                    <button className="email-button" onClick={toggleShare}>X</button>
                    <p className="copylink" onClick={copyLink}>Copy link</p>
                </div>
            )}
            {copySuccess && (
                <p className="copy-success-message">Link copied</p>
            )}
        </div>
    );
};

export default OtherProfile;
