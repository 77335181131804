/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useCurrentUser } from '../../../firebase/userContext';
import './gallery.css';
import GalleryModal from './galleryModal';

const Gallery = () => {
   const { fetchPhotos, photos, error, deletePost } = useCurrentUser();
   const [selectedPhotos, setSelectedPhotos] = useState(null)

   useEffect(() => {
       fetchPhotos();
   }, [fetchPhotos]);

   const handlePhotoClick = (photo) => {
    setSelectedPhotos(photo)
   };

   const closeModal = () => {
    setSelectedPhotos(null)
   }
     
   return (
       <div className='gallery-container'>
           {error && <p>{error}</p>}
           {photos.map(photo => (
               <img 
               key={photo.id} 
               src={photo.photoURL} 
               className='gallery-photos' 
               onClick={() => handlePhotoClick(photo)}
               />
           ))}

           {selectedPhotos && (
            <GalleryModal
                photo={selectedPhotos}
                onClose={closeModal} 
                deletePhoto={deletePost}
            />
           )}
       </div>
   );
};

export default Gallery;
