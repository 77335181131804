/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import logo from '../../assets/images/profile.png'
import { useNavigate } from "react-router-dom";


import './about.css'

const About = () => {
    const navigate = useNavigate();

    return (
        <div className="layout">
            <img src={logo} className="logo"/>
         
            <div className="about-container">
               
                <p className="about-text">Welcome to Profile 
                    <br/> <br/>
                    We are a dedicated space for creators to showcase their portfolios, 
                    connect with fellow creators, and share their vision. Here, users can build 
                    their creative profiles through photo uploads, emphasizing  
                     originality. We believe in the power of aesthetics, where quality and 
                    thoughtful expression take center stage over sheer volume. Whether you're a 
                    photographer, designer, or visual storyteller, our platform is crafted to 
                    highlight your profile and foster meaningful connections in the community.
                </p>
            </div>
                <button className="about-terms" onClick={() => navigate('/terms')}>terms</button>
                <button className="about-privacy" onClick={() => navigate('/privacy')}>privacy</button>
        </div>
    )
}

export default About;