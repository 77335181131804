/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef } from 'react';
import './upload.css'
import NavBar from '../../components/navBar'
import logo from '../../assets/images/profile.png'
import settingIcon from '../../assets/icons/settingIcon.png'
import { useCurrentUser } from '../../firebase/userContext';
import { useNavigate } from 'react-router-dom';

const Upload = () => {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const { uploadContent, currentUser } = useCurrentUser();
    const [imagePreview, setImagePreview] = useState(null); 
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            
            // Create a URL for the selected file to display it
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setImagePreview(fileReader.result);
            };
            fileReader.readAsDataURL(selectedFile);
        }
    };
    
    const uploadClick = () => {
        fileInputRef.current.click();
    };

    const handleUpload = async () => {
        if (file) {
            const success = await uploadContent(file, description);
            if(success) {
               navigate(`/profile/${currentUser.username}`)
            } else {
                alert('upload failed')
            }
        }
    }
    

    return (
        <div className='layout'>
            <NavBar />
            <img src={settingIcon} className='setting-icon' />
            <div className='upload-container'>
            <img src={logo} className='logo-upload' />

                    {/* <p className='upload-text'>upload</p> */}
                <div className='upload-photo' onClick={uploadClick}>
                {imagePreview ? (
                    <img src={imagePreview} className='imagepreview-upload' />
                ) : (

                    <p>upload photo</p>
                )}
                    <input
                    type='file'
                    accept='image/*'
                    onChange={handleFileChange}
                    style={{ display: 'none'}}
                    ref={fileInputRef} 
                    />
                   
                </div>
                <textarea className='description'
                placeholder='description' 
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxLength={80}
                />
                <button className='upload-button' onClick={handleUpload}>upload</button>
            </div>
        </div>
    )
}

export default Upload;