/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../firebase/userContext";
import logo from '../../assets/images/profile.png'


const Username = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [usernameError, setUsernameError] = useState('');
    const {  addUsername, error } = useCurrentUser();

    const handleCreateUsername = async () => {
        if (username.trim() === '') {
            setUsernameError('Username cannot be empty');
            return;
        }

        const success = await addUsername(username);
        if (success) {
            navigate(`/edit-profile/${username}`);
        } else {
            setUsernameError(error || "Could not save username");
        }
    }
    

    return (
        <div className="layout">
            <div className="username-container">
            <img src={logo} className="logo-username" />
               
              
                
                
                <p className="add-username">add a username</p>
                <input
                placeholder="username" 
                className="username-input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                maxLength={15}
                />
                {usernameError && <p className="error-message"> {usernameError} </p>}
                <button onClick={handleCreateUsername} className="create-button">create</button>
            </div>
        </div>
    )
}

export default Username;