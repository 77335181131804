

import '@fortawesome/fontawesome-free/css/all.css';
import React, { useState, useEffect } from 'react';
import './likeButton.css';
import { useCurrentUser } from '../firebase/userContext';
import { db } from '../firebase/config';
import { doc, getDoc, deleteDoc, setDoc } from 'firebase/firestore';

const LikeButton = ({ className = '', photoId, ownerId }) => { // Accepts a className prop with a default value
  const [liked, setLiked] = useState(false);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const checkIfLiked = async () => {
      if (currentUser?.uid) {
        const likeRef = doc(db, `users/${ownerId}/content/${photoId}/likes/${currentUser.uid}`);
        const likeDoc = await getDoc(likeRef);
        setLiked(likeDoc.exists());
      }
    };

    checkIfLiked();
  }, [photoId, ownerId, currentUser?.uid]); // Added currentUser.uid to dependencies

  const handleLikeToggle = async () => {
    if (!currentUser) return;

    const likeRef = doc(db, `users/${ownerId}/content/${photoId}/likes/${currentUser.uid}`);
    const likeDoc = await getDoc(likeRef);

    if (likeDoc.exists()) {
      await deleteDoc(likeRef);
      setLiked(false);
    } else {
      await setDoc(likeRef, { username: currentUser.username });
      setLiked(true);
    }
  };

  // const handleLikeToggle =async () => {
  //   const success = await likePhoto(photoId, ownerId);
  //   if (success !== null) {
  //     setLiked(success)
  //   } 
  // };

  return (
    <button
      onClick={handleLikeToggle}
      className={`heart-button ${liked ? 'liked' : ''} ${className}`} // Adds custom className
    >
      <i className={`fa${liked ? 's' : 'r'} fa-heart`}></i>
    </button>
  );
};

export default LikeButton;
