import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./pages/onboarding/register";
import './App.css'
import Username from "./pages/onboarding/username";
import EditProfile from "./pages/profile/editProfile";
import Profile from "./pages/profile/profile";
import Upload from "./pages/profile/upload";
import Setting from "./pages/profile/setting/setting";
import Notification from "./pages/profile/notification/notification";
import { UserProvider } from "./firebase/userContext";
import Home from "./pages/home/home";
import OtherProfile from "./pages/profile/otherProfile/otherProfile";
import Terms from "./pages/onboarding/terms";
import Privacy from "./pages/onboarding/privacy";
import About from "./pages/onboarding/about";
// import Password from "./pages/onboarding/password";



const App = () => {
  return (
  <UserProvider>
  <Router>
    <Routes>
    {/* <Route path="/" element={<Password />} /> */}
      <Route path="/" element={<Register />} />
      <Route path="/username" element={<Username />} />


      <Route path="/edit-profile/:username" element={<EditProfile />} />
      <Route path="/upload/:username" element={<Upload />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/notification/:username" element={<Notification />} />
      <Route path="/home" element={<Home /> } />
     
     
      <Route path="/profile/:username" element={<Profile />} />
      <Route path="/creator/:username" element={<OtherProfile />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/about" element={<About />} />
    </Routes>
  </Router>
  </UserProvider> 
  )
}

export default App;