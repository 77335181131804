import React, { useState, useEffect } from "react";

const SelectCategory = ({ onChange, className, value }) => {
    const [selectedCategory, setSelectedCategory] = useState(value || '');

    useEffect(() => {
      setSelectedCategory(value);
    }, [value])

    const handleChange = (event) => {
      const category = event.target.value;
      setSelectedCategory(category);
      if (onChange) {
        onChange(category);
      }
    }

    return (
        <select value={selectedCategory} onChange={handleChange} className={className} >
          <option value=""> Select a category</option>
          <option value='Blog'>Blog</option>
          <option value='Photographer'>Photographer</option>
          <option value='Designer'>Designer</option>
          <option value='Brand'>Brand</option>
          <option value='Writer'>Writer</option>
          <option value='Model'>Model</option>
          <option value='Stylist'>Stylist</option>

          {/* <option value='graphic designer'>Graphic Designer</option>
          <option value='musician'>Musician</option>
          <option value='band'>Band</option>
          <option value='filmmaker'>Filmmaker</option>
          <option value='actor'>Actor</option>
          <option value='athlete'>Athlete</option>
          <option value='production house'>Production House</option> */}
          
        </select>
    )
}

export default SelectCategory;