/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "../../../firebase/userContext";
import OtherModal from '../otherProfile/otherModal'


const OtherGallery = ({ username }) => {
    const { fetchPhotosByUsername, error } = useCurrentUser();
    const [photos, setPhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState(null);

    useEffect(() => {
        const loadPhotos = async () => {
            const fetchedPhotos = await fetchPhotosByUsername(username);
            setPhotos(fetchedPhotos);
        };

        loadPhotos();
    }, [username, fetchPhotosByUsername]);

    const handlePhotoClick = (photo) => {
        setSelectedPhotos(photo);
    };

    const closeModal = () => {
        setSelectedPhotos(null);
    };

    

    return (
        <div className="gallery-container">
            {error && <p> {error} </p>}
            {photos.map(photo => (
                <img
                  key={photo.id}
                  src={photo.photoURL}
                  className="gallery-photos"
                  onClick={() => handlePhotoClick(photo)}
                  />
            ) )}

            {selectedPhotos && (
                <OtherModal 
                  photo={selectedPhotos}
                  onClose={closeModal}
                />
            )}
         
        </div>
    )
}

export default OtherGallery;