import React, { createContext, useContext, useState, } from "react";

import { auth, db, storage } from "./config";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, deleteUser, signOut, sendPasswordResetEmail } from "firebase/auth";
import { collection, doc, setDoc, query, where, getDocs, orderBy, deleteDoc,getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const UserContext = createContext();

export const useCurrentUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [error, setError] = useState('');
    const [photos, setPhotos] = useState([]);

  
    
    
    

    const registerUser = async (email, password, termsChecked) => {
        if (!termsChecked) {
            setError("Please agree to the terms.");
            return false;
        }
    
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            setCurrentUser(userCredential.user);
            setError('');
            return true;
        } catch (err) {
            // Check for specific error codes and set custom error messages
            if (err.code === 'auth/email-already-in-use') {
                setError("Email already in use");
            } else if (err.code === 'auth/invalid-email') {
                setError("Invalid email address");
            } else if (err.code === 'auth/missing-password') {
                setError("Password is required");
            } else if (err.code === 'auth/weak-password') {
                setError("Password is too weak. It must be at least 6 characters.");
            } else {
                setError(err.message); // Handle other errors
            }
            return false;
        }
    };
    

  
    const loginUser = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
    
            if (userDoc.exists()) {
                const userData = { uid: userCredential.user.uid, ...userDoc.data() };
                setCurrentUser(userData); // Set user data with profile fields
                setError('');
                return userData; // Return full user data, including username
            } else {
                throw new Error("User profile not found in Firestore");
            }
        } catch (error) {
            console.error('Login error:', error);
    
            // Handle specific Firebase authentication errors
            if (error.code === 'auth/user-not-found') {
                setError('No account found with this email');
            } else if (error.code === 'auth/wrong-password') {
                setError('Incorrect password');
            } else {
                setError('Invalid login credentials'); // Generic error for other cases
            }
            return null;
        }
    };
    
    
    
    

    

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null); // Clear the currentUser state
           
        } catch (err) {
            console.log('Logout failed:', err);
        }
    };

    

    
    
    const addUsername = async (username) => {
        // Reset error before starting
        setError('');

        if (username.length < 3) {
            setError("Username must be at least 3 characters long.");
            return false;  // Stop further checks if error occurs
        }
      
    
        // Check if the username contains spaces
        if (/\s/.test(username)) {
            setError("Username cannot contain spaces.");
            return false;  // Stop further checks if error occurs
        }
    
        // Check if the username starts with a number
        if (/^\d/.test(username)) {
            setError("Username cannot start with a number.");
            return false;  // Stop further checks if error occurs
        }
    
        // Check if the username is valid (e.g., alphanumeric and underscores)
        if (!/^[a-zA-Z0-9_]+$/.test(username)) {
            setError("Username can only contain letters, numbers, and underscores.");
            return false;  // Stop further checks if error occurs
        }
    
        // Check if the username is unique
        const q = query(collection(db, "users"), where("username", "==", username));
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
            setError("Username is already taken");
            return false;  // Stop further checks if error occurs
        }
    
        // If all checks pass, proceed to save the username
        if (currentUser) {
            try {
                // Proceed to set the new username if it's valid and unique
                await setDoc(doc(db, "users", currentUser.uid), {
                    email: currentUser.email,
                    username: username,
                });
                setCurrentUser({ ...currentUser, username });
                return true;
            } catch (err) {
                setError('Error while saving username');
                return false;
            }
        }
    };
    


    const updateProfile = async (name, workEmail, category, profilePhoto, bio, username) => {
        try {
            let photoURL;
            if (profilePhoto instanceof File) {
                const storageRef = ref(storage, `profilePhotos/${currentUser.uid}`);
                await uploadBytes(storageRef, profilePhoto);
                photoURL = await getDownloadURL(storageRef);
            } else {
                // If profilePhoto is a URL, reuse it
                photoURL = profilePhoto;
            }
    
            await setDoc(doc(db, 'users', currentUser.uid), {
                name: name, 
                workEmail: workEmail,
                category: category,
                profilePhotoUrl: photoURL,  // Save the URL, new or existing
                username: username,
                bio: bio
            }, { merge: true });
    
            setCurrentUser({ ...currentUser, name, workEmail, category, profilePhotoUrl: photoURL, bio, username });
            setError('');
            return true;
        } catch (err) {
            setError('Could not update profile: ' + err.message);
            return false;
        }
    };

    const uploadContent = async (file, description) => {
        if (!currentUser) {
            setError('User not loggeg in')
            return false;
        }

        try {
            const storageRef = ref(storage, `gallery/${currentUser.uid}/${file.name}`);
            await uploadBytes(storageRef, file);
            const photoURL = await getDownloadURL(storageRef);
            const contentRef = collection(db, `users/${currentUser.uid}/content`)
            await setDoc(doc(contentRef), {
                photoURL: photoURL,
                description: description,
                time: new Date().toISOString(),
                name: currentUser.name,
                username: currentUser.username

            });

            setError('');
            return true;
        } catch (err) {
            setError('Could not upload content');
            return false;
        }
    }

     
    const fetchPhotos = async () => {
        if (!currentUser) {
            setError('User not logged in');
            return;
        }

        try {
            const contentRef = collection(db, `users/${currentUser.uid}/content`);
            const photosQuery = query(contentRef, orderBy('time', 'desc'));
            const snapshot = await getDocs(photosQuery);
            const photoData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPhotos(photoData);
        } catch (err) {
            setError('Could not fetch photos');
        }
    };


    
    const deleteAccount = async () => {
        if (!currentUser) {
            setError("User not logged in");
            return false;
        }
    
        try {
            // Step 1: Delete user's Firestore document
            await deleteDoc(doc(db, "users", currentUser.uid));
    
            // Step 2: Delete content sub-collection documents if any
            const contentRef = collection(db, `users/${currentUser.uid}/content`);
            const contentSnapshot = await getDocs(contentRef);
            for (const docSnapshot of contentSnapshot.docs) {
                await deleteDoc(docSnapshot.ref);
            }
    
            // Step 3: Delete user from Firebase Authentication
            await deleteUser(auth.currentUser);
    
            // Clear context and reset state
            setCurrentUser(null);
            setPhotos([]);
            setError('');
            return true;
        } catch (err) {
            setError("Could not delete account: " + err.message);
            return false;
        }
    };

    const deletePost= async (photoId) => {
        if (!currentUser) {
            setError('User not logged in');
            return;
        }
    
        try {
            const photoRef = doc(db, `users/${currentUser.uid}/content`, photoId);
            await deleteDoc(photoRef); // Only delete the Firestore document
            setPhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== photoId)); // Update state
            setError('');
        } catch (err) {
            setError('Could not delete photo from Firestore: ' + err.message);
        }
    };
    

   
    
    const fetchUserProfileByUsername = async (username) => {
        try {
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("username", "==", username));
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();
                userData.uid = userDoc.id; // Add this line to ensure uid is present
    
                // Fetch gallery photos
                const contentRef = collection(db, `users/${userDoc.id}/content`);
                const photosQuery = query(contentRef, orderBy('time', 'desc'));
                const photoSnapshot = await getDocs(photosQuery);
                const photos = photoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
                return { ...userData, photos };
            } else {
                setError("User not found");
                return null;
            }
        } catch (err) {
            setError("Could not fetch profile: " + err.message);
            return null;
        }
    };
    


    const fetchPhotosByUsername = async (username) => {
        try {
            // Find the user by username to get their unique ID
            const userSnapshot = await getDocs(query(collection(db, 'users'), where('username', '==', username)));
            if (userSnapshot.empty) throw new Error('User not found');
            
            const userId = userSnapshot.docs[0].id;

            // Fetch photos from the user's content collection
            const contentRef = collection(db, `users/${userId}/content`);
            const photosQuery = query(contentRef, orderBy('time', 'desc'));
            const snapshot = await getDocs(photosQuery);

            return snapshot.docs.map(doc => ({ 
                id: doc.id,
                photoURL: doc.data().photoURL,
                username: doc.data().username,
                name: doc.data().name,
                description: doc.data().description,
                time: doc.data().time,
                ownerId: doc.ref.parent.parent.id
             }));
        } catch (err) {
            console.error("Error fetching photos by username:", err);
            setError('Could not fetch photos by username');
            return [];
        }
    };

    
    const followUser = async (userToFollow) => {
        if (!currentUser) return;
    
        const followingRef = collection(db, `users/${currentUser.uid}/following`);
        const followersRef = collection(db, `users/${userToFollow.uid}/followers`);
    
        try {
            // Add current user's username to the following sub-collection of the current user
            await setDoc(doc(followingRef, userToFollow.uid), {
                username: userToFollow.username,
            });
    
            // Add current user's username to the followers sub-collection of the user being followed
            await setDoc(doc(followersRef, currentUser.uid), {
                username: currentUser.username,
            });
    
            return true;
        } catch (error) {
            setError('Could not follow user: ' + error.message);
            return false;
        }
    };

    
    
    const unfollowUser = async (userToUnfollow) => {
        if (!currentUser) return;
    
        const followingRef = doc(db, `users/${currentUser.uid}/following`, userToUnfollow.uid);
        const followersRef = doc(db, `users/${userToUnfollow.uid}/followers`, currentUser.uid);
    
        try {
            // Remove current user's username from the following sub-collection of the current user
            await deleteDoc(followingRef);
    
            // Remove current user's username from the followers sub-collection of the user being unfollowed
            await deleteDoc(followersRef);
    
            return true;
        } catch (error) {
            setError('Could not unfollow user: ' + error.message);
            return false;
        }
    };
    
    const isFollowing = async (userToCheck) => {
        if (!currentUser || !userToCheck || !userToCheck.uid) return false; // Add this check
    
        const followingRef = doc(db, `users/${currentUser.uid}/following`, userToCheck.uid);
        const docSnap = await getDoc(followingRef);
        
        return docSnap.exists();
    };


    const likePhoto = async (photoId, ownerId) => {
        if (!currentUser) {
            setError('User not logged in')
            return;
        }

        try {
            const likeRef = doc(db, `users/${ownerId}/content/${photoId}/likes/${currentUser.uid}`);
            const likeDoc = await getDoc(likeRef);

            if (likeDoc.exists()) {
                await deleteDoc(likeRef)
                setError('')
                return false
            } else {
                await setDoc(likeRef, { username: currentUser.username });
            setError('');
            return true;
            }
        
        } catch (error) {
            setError('Could not like photo:')
            return false
        }
    }

    const fetchFollowers = async (uid) => {
        if (!uid) return [];

        try {
            const followersRef = collection(db, `users/${uid}/followers`);
            const snapshot = await getDocs(followersRef);

            const followersList = snapshot.docs.map(doc => doc.data())
            return followersList;
        } catch (err) {
            console.error('Error fetching')
        }
    }

    const fetchFollowing = async (uid) => {
        if (!uid) return [];
    
        try {
            // Get a reference to the "following" sub-collection for the user
            const followingRef = collection(db, `users/${uid}/following`);
            const snapshot = await getDocs(followingRef);
    
            // Map through the documents and extract the user data
            const followingList = snapshot.docs.map(doc => doc.data());
            return followingList;
        } catch (err) {
            console.error('Error fetching following:', err);
            return [];
        }
    };

    const fetchLikes = async (uid) => {  // Ensure async here
        if (!uid) return [];
        
        try {
          const likesRef = collection(db, `users/${uid}/content`);
          const snapshot = await getDocs(likesRef);
        
          const likesList = [];
          for (const doc of snapshot.docs) {
            const photoLikesRef = collection(doc.ref, "likes");
            const likeSnapshot = await getDocs(photoLikesRef);
            likeSnapshot.forEach((likeDoc) => {
              likesList.push(likeDoc.data());
            });
          }
        
          return likesList;
        } catch (err) {
          console.error("Error fetching likes", err);
          return [];
        }
      };


      const resetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            setError('');
            return true; // Return true if successful
        } catch (err) {
            setError("Could not send reset email: " + err.message); // Set error if failed
            return false; // Return false if there is an error
        }
    };


    const fetchProfileDataByUsername = async (username) => {
        const userRef = doc(db, 'users', username);
        const docSnap = await getDoc(userRef);
      
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          console.log("No profile data found");
          return null;
        }
      };
    
    
      
    
  
    

return (
    <UserContext.Provider value={{ 
        currentUser, 
        setCurrentUser, 
        registerUser, 
        error, 
        addUsername, 
        updateProfile,
        uploadContent,
        fetchPhotos,
        photos,
        loginUser,
        deleteAccount,
        fetchUserProfileByUsername,
        fetchPhotosByUsername,
        followUser,
        unfollowUser,
        isFollowing,
        likePhoto,
        fetchFollowers,
        fetchLikes,
        fetchFollowing,
        deletePost,
        handleLogout,
        resetPassword,
        fetchProfileDataByUsername
        
       
        
       
        
        
        
      
         }}>
        {children}
    </UserContext.Provider>
 )
};