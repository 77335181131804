/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import './navbar.css'
import { useNavigate } from "react-router-dom";
import profileIcon from '../assets/icons/profileIcon.png'
import notificationIcon from '../assets/icons/notificationIcon.png'
import { useCurrentUser } from "../firebase/userContext";


const NavBar = () => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();

    const handleProfileClick = () => {
        if (currentUser) {
            navigate(`/profile/${currentUser.username}`)
        }
    }

    const handleNotificationClick = () => {
        if (currentUser) {
            navigate(`/notification/${currentUser.username}`)
        }
    }

    return (
        <div className="navbar-container">
            <h1 className="explore-profile" onClick={() => navigate('/home')} src={profileIcon}>explore</h1>
            <img onClick={handleProfileClick} src={profileIcon} className="profileicon"/>
            <img onClick={handleNotificationClick} src={notificationIcon} className="notification-icon"/>
        </div>
    )
}

export default NavBar;