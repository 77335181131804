/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import logo from '../../assets/images/profile.png'

const Terms = () => {
    return (
        <div className="layout">
            <img src={logo} className="logo" />
            <div className="terms-text">
                <p>
                    Terms of Service:
                    <br />
                    <br />
                   Welcome to Profile! By using our platform, you agree to comply 
                   with the following terms and conditions. Please read them carefully. 
                    <br />
                    <br />
                    <br />
                    1. Acceptance of Terms
                    <br />
                    <br />
                    By creating an account on Profile, you agree to 
                    these Terms of Service and to abide by them while using the platform. 
                    If you do not agree, please do not use our platform.
                    <br />
                    <br />
                    2. Use of platform
                    <br />
                    <br />
                    Profile is a community platform for creators to upload photos, 
                    create portfolios, and connect with other creators. 
                    You agree to use the platform responsibly and only for its intended purposes.
                    <br /> <br />
                    3. Account Registration
                    <br /> <br />
                    You must provide accurate and complete information when registering. You are responsible for 
                    keeping your account secure and for any activities that occur under your account.
                    <br /> <br />
                    4. Content and Intellectual Property
                    <br /> <br />
                    You retain ownership of the content you post on Profile. By uploading content, you grant Profile 
                    a license to display, distribute, and promote your content on the platform. You agree not to upload any content 
                    that is offensive, illegal, or infringes on the intellectual property of others.
                    <br /> <br />
                    5. Limitation of Liability
                    <br /> <br />
                    Profile is not responsible for any damages resulting from your use of the platform, 
                    including data loss or unauthorized access to your account. 
                    We strive to maintain a secure environment, but we cannot guarantee absolute security.
                    <br /> <br />
                    6. Changes to Terms
                    <br /> <br />
                    We may update these Terms of Service from time to time. We will notify you of any 
                    significant changes, but encourage you to review the Terms regularly.
                    <br /> <br />
                    7. Contact
                    <br /> <br />
                    If you have questions or concerns regarding these 
                    Terms, please contact us at info@profileland.net.
                </p>
            </div>
          
           

            
        </div>
    )
}

export default Terms;