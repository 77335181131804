/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import NavBar from "../../components/navBar";
import logo from '../../assets/images/profile.png';
import { collectionGroup, getDocs } from "firebase/firestore";
import './home.css';
import HomeModal from "./homeModal";

const Home = () => {
    const [allPhotos, setAllPhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState(null)
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchAllUsersPhotos = async () => {
            try {
                // Reference to the content collection across users
                const contentRef = collectionGroup(db, 'content');
                const snapshot = await getDocs(contentRef);

                // Map through documents and store necessary details
                const fetchedPhotos = snapshot.docs.map(doc => ({
                    id: doc.id,
                    photoURL: doc.data().photoURL,
                    username: doc.data().username,
                    name: doc.data().name,
                    time: doc.data().time,
                    description: doc.data().description,
                    ownerId: doc.ref.parent.parent.id
                }));

                setAllPhotos(fetchedPhotos);
            } catch (err) {
                console.error("Error fetching photos:", err);
                setError('Could not fetch photos');
            }
        };

        fetchAllUsersPhotos();
    }, []);

    const handlePhotoClick = (photo) => {
        setSelectedPhotos(photo)
    };

    const closeModal = () => {
        setSelectedPhotos(null);
    };

    return (
        <div className="layout">
            <img src={logo} className="logo-home" />
            <NavBar />
            <div className="home-container">
                {error && <p className="error-message">{error}</p>}
                {allPhotos.map(photo => (
                    <img
                        key={photo.id}
                        src={photo.photoURL} 
                        className="home-photo"
                        onClick={() => handlePhotoClick(photo)}
                    />
                ))}
                <HomeModal photo={selectedPhotos} onClose={closeModal}/>
            </div>
        </div>
    );
}

export default Home;
