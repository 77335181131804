/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import logo from '../../assets/images/profile.png'

const Privacy = () => {
    return (
        <div className="layout">
           <img src={logo} className="logo" />
           <div className="terms-text">
                <p>
                    Privacy Policy
                    <br />
                    <br />
                    Your privacy is important to us at Profile. 
                    This Privacy Policy describes how we collect, use, and 
                    safeguard your information when you use our platform.
                    <br />
                    <br />
                    <br />
                    1. Information we collect
                    <br />
                    <br />
                    We collect basic personal information, such as your name, email address, and any other details you provide 
                    upon registration. Additionally, we collect content you choose to upload, including photos and 
                    information on your connections within the platform. No further personal information is collected 
                    unless you directly provide it.
                    <br />
                    <br />
                    2. Use of Information
                    <br />
                    <br />
                    Your information is used to enable your participation on the platform, allowing you to create a portfolio, 
                    connect with other creators, and interact within the community. We may also use aggregated data to improve our platform's 
                    functionality and may contact you via email with updates, feature announcements, or other relevant information.
                    <br /> <br />
                    3. Data Security
                    <br /> <br />
                    We prioritize the security of your personal data, implementing measures to protect it from unauthorized access,
                     loss, or misuse. However, as with any internet-based service, we cannot guarantee complete security.
                    <br /> <br />
                    4. Retention of Information
                    <br /> <br />
                    Your information is retained as long as your account is active or as necessary to
                    provide services. You may request deletion of your account and data by contacting us directly.
                    <br /> <br />
                    5. Data Sharing
                    <br /> <br />
                    Your personal data is not shared with third parties, except as required by law or to 
                    protect the rights, property, or safety of Profile, its users, or the public.
                    <br /> <br />
                    6. User Rights
                    <br /> <br />
                    You have the right to access, correct, or 
                    delete your personal information at any time. Contact us at Profile to exercise these rights.
                    <br /> <br />
                    7. Changes to Privacy Policy
                    <br /> <br />
                    We may periodically update this Privacy Policy. In the event of significant changes, 
                    we will notify you by email or within the platform.
                    <br /> <br />
                    8. Contact Us
                    <br /> <br />
                    For any questions or concerns regarding our Privacy Policy, please reach out to us at info@profileland.net.
                    <br /> <br /> <br />
                    Profile is dedicated to safeguarding your privacy and respecting your personal data 
                    as you explore and connect on our platform. Thank you for your trust.

                </p>
            </div>
        </div>
    )
}

export default Privacy;