/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './editProfile.css'
import logo from '../../assets/images/profile.png'
import settingIcon from '../../assets/icons/settingIcon.png'
import SelectCategory from "../../components/category";
import { useCurrentUser } from "../../firebase/userContext";
import { db } from "../../firebase/config";
import { doc, getDoc, query, collection, where, getDocs } from "firebase/firestore";


const EditProfile = () => {
    const navigate = useNavigate();
    const { currentUser, updateProfile} = useCurrentUser();

    const [name, setName] = useState('');
    const [workEmail, setWorkEmail] = useState('');
    const [category, setCategory] = useState('');
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [bio, setBio] = useState('');
    const [username, setUsername] = useState(currentUser.username)
    const [photoError, setPhotoError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [nameError, setNameError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [photoPreview, setPhotoPreview] = useState('');

    useEffect(() => {
        const fetchProfileData = async () => {
            if (currentUser) {
                const userRef = doc(db, 'users', currentUser.uid);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setName(data.name || '');
                    setWorkEmail(data.workEmail || '');
                    setCategory(data.category || '');
                    setBio(data.bio || '');
                    if (data.profilePhotoUrl) {
                        setPhotoPreview(data.profilePhotoUrl)
                    }
                } else {
                    console.log('No profile photo found')
                }
            }
        };
        fetchProfileData();
    }, [currentUser])

    const handleProfilePhoto = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfilePhoto(file);
            setPhotoError('');

            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoPreview(reader.result);
            };
            reader.readAsDataURL(file)
        }
    };

    // 
    const handleSave = async () => {
        // Reset errors before validation
        setUsernameError('');
        setPhotoError('');
        setCategoryError('');
        setNameError('')
    
        // Validate username (same logic as in addUsername)
        if (username.length < 3) {
            setUsernameError("Username must be at least 3 characters long.");
            return;
        }
    
        if (/\s/.test(username)) {
            setUsernameError("Username cannot contain spaces.");
            return;
        }
    
        if (/^\d/.test(username)) {
            setUsernameError("Username cannot start with a number.");
            return;
        }
    
        if (!/^[a-zA-Z0-9_]+$/.test(username)) {
            setUsernameError("Username can only contain letters, numbers, and underscores.");
            return;
        }
    
        // Check if username is unique
        const isUnique = await checkUsername();
        if (!isUnique) {
            setUsernameError("Username is already taken.");
            return;
        }
    
        // Validate profile photo
        if (!profilePhoto && !photoPreview) {
            setPhotoError("Please upload a profile photo.");
            return;
        }

        if (!category) {
            setCategoryError("Please select a category.");
            return;
        }

        if (!name) {
            setNameError('Please enter a name')
            return;
        }
    
        // Prepare photo to upload
        const photoToUpload = profilePhoto || photoPreview;
    
        // Update profile
        const success = await updateProfile(name, workEmail, category, photoToUpload, bio, username);
        if (success) {
            navigate(`/profile/${username}`);
        }
    }
    

    const checkUsername = async () => {
        const usernameQuery = query(
            collection(db, 'users'),
            where('username', '==', username)
        );

        const querySnapshot = await getDocs(usernameQuery);

        return querySnapshot.empty || (querySnapshot.docs[0]?.id === currentUser.uid);
    }


    return (
        <div className="layout">
            <img onClick={() => navigate('/setting')} src={settingIcon} className="setting-icon" />
           <div className="ep-container">
            <img src={logo} className="logo" />
           
            <p className="edit-profile">edit profile</p>
            <div className="ep-profilephoto" onClick={() => document.getElementById('fileInput').click()}>

               {photoPreview ? (
                <img src={photoPreview} className="ep-photopreview" />
               ) : (
                <p>add profile photo</p>
               )}
                <input
                type="file"
                id="fileInput"
                style={{ display: 'none'}}
                onChange={handleProfilePhoto} 
                />
            </div>
            <input
            placeholder="name"
            className="ep-name" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={15}
            />
             <input
            placeholder="username"
            className="ep-username" 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            maxLength={15}
            
            />

           {usernameError && <p className="ep-error-message">{usernameError}</p>}

            <input
            placeholder="work-email"
            className="ep-email"
            value={workEmail}
            onChange={(e) => setWorkEmail(e.target.value)} 
            />

            <SelectCategory
            className='ep-category' 
            value={category}
            onChange={setCategory}
            />
             {nameError && <p className="ep-error-message">{nameError} </p>}
             {photoError && <p className="ep-error-message">{photoError} </p>}
             {categoryError && <p className="ep-error-message">{categoryError}</p>}
            <button onClick={handleSave} className="ep-save">save</button>
            <textarea 
            className="ep-bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            maxLength={100}
             ></textarea>
           </div>
        </div>
    )
}

export default EditProfile;